import { initReactI18next } from "react-i18next";

import i18next from "i18next";

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    en: {
      translation: {
        homepage: {
          footer_airport: 'Airport:'
        },
        menulabels:{
          labelBoutique: "E-Boutique"
        },
        service: {
          serviceCategory: "Category",
          beforeSecurityChecks: "Before security checks",
          afterSecurityChecks: "After security checks"
        },
        login: {
          title: "Login",
          placeholderEmail: "Address e-mail *",
          placeholderPassword: "Password *",
          loginButton: "Sign in",
          forgetPasswordText: "Forgot Password ?",
          newUserText: " New user? Sign up",
          wrongEmailOrPassword: "Email or password not valid",
          wrongEmail: "Email not valid"
        },
        shop: {
          noShopsFound: "No stores match your search criteria",
          noProductsForShop: "There are no products available for this shop",
          chooseForYou: "CHOOSE FOR YOU",
          infoShop: "STORE INFORMATION",
          category: "Category",
          beforeFilter: "Before Security Checks",
          afterFilter: "After Security Checks",
          fashion: "FASHION AND FASHION ACCESSORIES",
          beauty: "WELLNESS AND BEAUTY",
          food: "GASTRONOMY/CONFECTIONERY",
          dutyFree: "DUTY FREE",
          books: "BOOKS/NEWS PAPER\\SOUVENIR",
          restaurants: "RESTAURANT",
          detail: "DETAIL",
          book: "BOOK",
          map: "See map",
          inAirport: "airport",
          br: "bar-restaurants",
          shops: "shops",
          openingShop: "Opening hours",
          whereShop: "Where to find us",
          helpShop: "Need help?",
          contacts: "Contacts"
        },
        form: {
          airport:"Airport",
          motivation: "MOTIVATION",
          argument: "TOPIC",
          solution: "SOLUTION",
          selectFile: "Select File",
          selectMotivation: "Select a Motivation",
          selectArgument: "Select a topic",
          selectOption: "Select an option",
          sendEmailNow: "SEND EMAIL NOW",
          validEmail: "Enter a valid email",
          none: "None"
        },
        flights: {
          baggageCarousel: 'Baggage Carousel',
          flightNumber: 'Flight number',
          departingFrom: 'Departing to:',
          comingFrom: 'Coming from:',
          departures: 'Departures',
          arrivals: 'Arrivals',
          details: 'Details',
          lastUpdate: 'Last update',
          lastUpdateDetail: 'Updated at',
          nextFlights: 'Next flights',
          previousFlights: 'Previous flights',
          searchFlights: "Search flights",
          livesearchPlaceholder: "Search flight, destination...",
          to: "To",
          from: "From",
          departuresCheckIn1: "Arrive at the airport early, following the airline's guidelines. Ensure you have the required documents for your destination country.",
          departuresCheckIn2: "Arrive at the airport early, following the airline's guidelines. Ensure you have the required documents for your destination country.",
          flightTo: " TO ",
          flightFrom: " FROM ",
          follow: "Follow",
          trackingMode: "Track Your Flight",
          flightTracking: "Flight Tracking",
          flightNotTrackable: "Flight Not Trackable",
          flightTrackingSuccess: "Flight tracking activated",
          flightTrackingAlreadySubscribed: "Tracking already active for this flight, would you like to deactive it?",
          flightTrackingMaxSubscription: "Is not possible to activate flight tracking on more then three flights",
          flightGenericError: "There was an error, retry",

          flightDeparture: "DEPARTURE",
          flightArrival: "ARRIVAL",

          flightDateOn: "on ",
          flightDateAt: "at ",
          flightDetailEmpty: "Flight information not available",
          flightEmpty: "No flights",
          flightsDepartureGoTo: "Departures",
          flightsArrivalGoTo: "Arrivals",
          flightsSearchCta: "Find out the flight you’re interested in",

          flightTrackingNotLogged: "You must be logged in to activate flight tracking",
          flightTrackingDisable: "Deactivate",
          flightTrackingSuspend: "Flight tracking deactivated",
          buyTickets: 'Buy tickets',
          showMore: 'Show more',
          noResults: 'No results',
          imessageText: 'Subscribe to flight ',
          whatsappText: 'Subscribe to flight '
        },
        account: {
          welcome:"Welcome to your personal area! Track your flight and check your purchases. Manage your account with just a simple click.",
          hello: "Hi",
          yes: "YES",
          points1: "You have",
          points2: "points",
          points3: "You're",
          points4: "away from winning your next prize.",
          points5: "Congrats! Redeem your reward and continue collecting points with ViaMilano Program.",
          preference: "Preferences",
          changeInfo: "Change your info",
          changeInfosubTitle:
            "You can modify your personal info at any moment so that your account is always up to date.",
          data: "User Data",
          name: "Name",
          surname: "Surname",
          birthday: "Date of Birth",
          cell: "Cellphone number",
          sex: "Sex",
          save: "SAVE CHANGES",
          backToSettings: "BACK TO SETTINGS",
          modify: "Modify",
          account: "You can handle you account and your subscriptions here",
          consent: "Modify privacy consent",
          consentTitle:
            "Having read the privacy information for the processing of my personal data pursuant to art. 13 of Regulation (EU) 2016/679 by the Joint Controllers of the S.E.A. Treatment, and art. 6 of Regulation (EU) 2016/679 I give the following consents:",
          consentSubTitle:
            "To receive communications for marketing purposes through:",
          privacy:
            "I declare that I have read the Privacy Information and consent to the processing of my personal data provided in the context of using the Service.",
          personalData:
            "I authorize the processing of my personal data for the sending of airport information, promotional and commercial communications relating to SEA services, through its channels, for promotional, commercial and marketing purposes, as indicated in the Privacy Policy.",
          personalDataprofile:
            "I authorize the processing of my personal data for profiling activities, including but not limited to the processing of data relating to my purchases, my preferences and consumption choices for market analysis and statistics activities, creation of profiles (individual and/or or aggregated), as indicated in the Privacy Policy.",
          myAccountTitle:
            "Personal information: user data, telepass and preferences",
          pswSubTitle:
            "It's possible to update your password at any moment in order to keep your account safe.",
          changepsw: "Change password",
          privacyTitle: "Verify your privacy consents",
          privacySubTitle:
            "You can check or modify your consents and subscriptions here.",
          deleteTitle: "Delete my account",
          deleteSubTitle:
            "Delete your account and Fidelity card Via Milano Program.",
          telepassTitle: "Connect your Telepass code",
          telepassSubtitle:
            "Set your Telepass code and collect ViaMilano Program points",
          telepassNumber: "Telepass number",
          preferenceTitle: "Preferences: the more you add the more you get",
          preferenceSubtitle:
            "Do you wish for SEA to know you better in order to give you a better service in line with your needs?",
          fidelityCardNumber: "Card number:",
          showFidelityCard: "Show fidelity card",
          getYourFidelityCard: "Get your fidelity card",
          fidelityCard: "My fidelity card",
          flightsTracking: "Flight tracking",
          myPurchases: "My purchases",
          myAccount: "My account",
          logout: "Logout",
          flightTrackingLast6Month: "Last 6 months",
          flightTrackingLast3Month: "Last 3 months",
          flightTrackingLastMonth: "Last month",
          genderM: "Male",
          genderF: "Female",
          cardSubscription :"Sign up for the program that allows you to collect points with every purchase, obtaining rewards and benefits.",
          notFound: "Not found",
          subscribe: "Subscribe"
        },
        parking: {
          today: "Today",
          hour: "Hour",
          time: "Time",
          searchParking: "Search parking",
          chooseTime: 'Select time',
          list: 'List',
          map: 'Map'
        },
        trains: {
          searchTrain: "Search trains",
          linkTrain: "/train"
        },
        travel_inspiration: {
          city: "City",
          sea: "Sea",
          culture: "Culture",
          nature: "Nature",
          departuresCheckIn2: "Arrive at the airport hours in advance: 2",
          findYourFlightTo: "Find your flight to "
        },
        destinations: {
          noResult: "Your research has no results.",
          selectContinent: "Select continent",
          selectNation: "Select country/territory",
          selectCity: "Select city",
          flight: "Flight",
          airline: "Airline",
          airport: "Airport",
          activity: "Operating period",
          activityShort: "Active",
          frequency: "Time and Frequency",
          frequencyShort: "Frequency"
        },
        airlines: {
          selectCode: "Select code",
          airlines: "Airline",
          code: "Code",
          goToWebSite: "WEB SITE",
          contactAirline: "Contact the airline",
          contact: "Contact"
        },
        purchases: {
          date: "Date",
          details: "Details",
          pnr: "Purchase Code (PNR)",
          tot: "Total",
          ticket: "VIEW TICKET",
          shopping: "Start shopping!",
          nopurchases: "No purchases found"
        },
        loyalty: {
          programDescription: "With your Via Milano Program loyalty card you can earn points with your purchases of products or services at Milan Malpensa airport. In shops, just show the loyalty card code during the purchase.",
          rules: 'Discover the regulation',
          knowMore: 'Learn more',
          showFidelityProgram: 'Program',
          downloadCard: 'Download fidelity card',
          myFidelityProgram: 'My loyalty program',
          pointsLabel: 'You have',
          points: 'Points',
          pointsOverview: 'Points overview',
          date: 'Date',
          detail: 'Detail',
          rewardCatalog: 'Rewards catalog',
          myRewards: 'My rewards',
          rewardHistoryCta: 'See my rewards history',
          residue: 'Residual',
          request: 'Request',
          confirm: "Are you sure to redeem the prize?",
          cancel: 'Cancel',
        },
        news: {
          seeAllTheNews: "SEE ALL THE NEWS",
          readArticle: 'READ ARTICLE'
        },
        faqs: {
          seeAllTheFaqs: "SEE ALL THE FAQS"
        },
        globalSearch: {
          search: "Search...",
          searchBtn: "SEARCH",
          delete: "Delete",
          noResults: "There are no results for",
          all: "All",
          resultsFor: "search results for",
          visitPage: "VISIT PAGE"
        }
      },
    },
    it: {
      translation: {
        homepage: {
          footer_airport: 'Aeroporto:'
        },
        menulabels:{
          labelBoutique: "E-Boutique"
        },
        service: {
          serviceCategory: "Categoria",
          beforeSecurityChecks: "Prima dei controlli di sicurezza",
          afterSecurityChecks: "Dopo dei controlli di sicurezza"
        },
        login: {
          title: "Login",
          placeholderEmail: "Indirizzo e-mail *",
          placeholderPassword: "Password *",
          loginButton: "Accedi",
          forgetPasswordText: "Password dimenticata?",
          newUserText: " Nuovo utente? Registrati",
          wrongEmailOrPassword: "Email o password non validi",
          wrongEmail: "Email non valida"
        },
        shop: {
          noShopsFound: "Nessun negozio corrisponde ai criteri di ricerca",
          noProductsForShop: "Non ci sono prodotti disponibili per questo negozio",
          chooseForYou: "SCELTI PER TE",
          infoShop: "INFORMAZIONI NEGOZIO",
          category: "Categoria",
          beforeFilter: "Prima dei filtri di sicurezza",
          afterFilter: "Oltre i filtri di sicurezza",
          fashion: "MODA/ACCESSORI",
          beauty: "BENESSERE/BELLEZZA",
          food: "GASTRONOMIA/PASTICCERIA",
          dutyFree: "DUTY FREE",
          books: "LIBRI/GIORNALI/SOUVENIR",
          restaurants: "RISTORANTE",
          detail: "DETTAGLIO",
          book: "PRENOTA",
          map: "Vedi mappa",
          inAirport: "in-aeroporto",
          br: "bar-ristoranti",
          shops: "negozi",
          openingShop: "Orari apertura",
          whereShop: "Dove ci trovi",
          helpShop: "Possiamo aiutarti?",
          contacts: "Contatti"
        },
        form: {
          airport:"Aeroporto",
          motivation: "MOTIVAZIONE",
          argument: "ARGOMENTO",
          solution: "SOLUZIONE",
          selectFile: "Seleziona File",
          selectMotivation: "Seleziona una motivazione",
          selectArgument: "Seleziona un argomento",
          selectOption: "Seleziona un'opzione",
          sendEmailNow: "INVIA EMAIL ORA",
          validEmail: "Inserisci una email valida",
          none: "Nessuno"
        },
        flights: {
          baggageCarousel: 'Nastro bagagli',
          flightNumber: 'Numero volo',
          departingFrom: 'In partenza per:',
          comingFrom: 'In arrivo da:',
          departures: 'Partenze',
          arrivals: 'Arrivi',
          details: 'Dettagli',
          lastUpdate: 'Ultimo aggiornamento',
          lastUpdateDetail: 'Aggiornato alle',
          nextFlights: 'Vedi voli successivi',
          previousFlights: 'Vedi voli precedenti',
          searchFlights: "Cerca voli",
          livesearchPlaceholder: "Cerca volo, destinazione...",
          departuresCheckIn1: "Arriva in aeroporto in anticipo, seguendo le indicazioni della compagnia aerea. Assicurati di avere i documenti necessari per il tuo viaggio.",
          departuresCheckIn2: "Arriva in aeroporto in anticipo, seguendo le indicazioni della compagnia aerea. Assicurati di avere i documenti necessari per il tuo viaggio.",
          to: "A",
          from: "Da",
          flightTo: " PER ",
          flightFrom: " DA ",
          follow: "Segui",
          trackingMode: "Attiva Tracking",
          flightTracking: "Tracking volo",
          flightNotTrackable: "Volo non tracciabile",
          flightTrackingSuccess: "Il tracking del volo è stato attivato",
          flightTrackingAlreadySubscribed: "Il tracking è già attivo per questo volo, vuoi disattivarlo?",
          flightTrackingMaxSubscription: "Non è possibile attivare il flight tracking su più di tre voli",
          flightGenericError: "Si è verificato un errore, riprova",
          flightDeparture: "PARTENZA",
          flightArrival: "ARRIVO",
          flightDateOn: "il ",
          flightDateAt: "alle ",
          flightDetailEmpty: "Informazioni volo non disponibili",
          flightEmpty: "Nessun volo presente",
          flightsDepartureGoTo: "Voli in partenza",
          flightsArrivalGoTo: "Voli in arrivo",
          flightsSearchCta: "Cerca i voli che ti interessano!",
          flightTrackingNotLogged: "Devi aver effettuato l'accesso per attivare il tracking del volo",
          flightTrackingDisable: "Disattiva",
          flightTrackingSuspend: "Il tracking del volo è stato disattivato",
          buyTickets: 'Acquista biglietti',
          showMore: 'Mostra di più',
          noResults: 'Nessun risultato',
          imessageText: 'Ricevi le informazioni del volo ',
          whatsappText: 'Ricevi le informazioni del volo '
        },
        account: {
          welcome:"Benvenuto nell'area personale! Traccia il tuo volo e visualizza i tuoi acquisti. Gestisci il tuo account con un semplice clic.",
          hello: "Ciao",
          yes: "SI",
          points1: "Hai",
          points2: "punti",
          points3: "Ti mancano",
          points4: "per aggiudicarti il prossimo premio.",
          points5: "Complimenti! Riscatta il tuo premio e continua a collezionare punti con ViaMilano Program.",
          preference: "Preferenze",
          changeInfo: "Cambia le informazioni",
          changeInfosubTitle:
            "Puoi modificare le tue informazioni personali in qualsiasi momento in modo che il tuo account sia sempre aggiornato.",
          data: "Dati Utente",
          name: "Nome",
          surname: "Cognome",
          birthday: "Data di nascita",
          cell: "Cellulare",
          sex: "Sesso",
          save: "SALVA MODIFICHE",
          backToSettings: "TORNA AD IMPOSTAZIONI",
          modify: "Modifica",
          account: "Puoi gestire il tuo account e le tue sottoscrizioni qui",
          consent: "modifica consensi privacy",
          consentTitle:
            "Presa visione dell'informativa privacy per il trattamento dei miei dati personali resa ai sensi dell'art. 13 del Regolamento (UE) 2016/679 dai Contitolari del Trattamento S.E.A., e art. 6 del Regolamento (UE) 2016/679 presto i seguenti consensi:",
          consentSubTitle:
            "Per ricevere comunicazioni con finalità di marketing attraverso:",
          privacy:
            "Dichiaro di aver preso visione dell'Informativa Privacy e di acconsentire al trattamento dei miei dati personali forniti nell'ambito della fruizione del Servizio.",
          personalData:
            "Autorizzo il trattamento dei miei dati personali per l'invio di informazioni aeroportuali, comunicazioni promozionali, commerciali relative a servizi di SEA, tramite i suoi canali, per finalità promozionali, commerciali e di marketing, come indicato nell'Informativa Privacy.",
          personalDataprofile:
            "Autorizzo il trattamento dei miei dati personali per attività di profilazione, ivi incluso a mero titolo esemplificativo il trattamento dei dati relativi ai miei acquisti, le mie preferenze e scelte di consumo per attività di analisi di mercato e statistiche, creazione di profili (individuali e/o aggregati), come indicato nell'Informativa Privacy.",
          myAccountTitle:
            "Informazioni personali: dati utente, telepass e preferenze",
          pswSubTitle:
            "È possibile aggiornare la password in qualsiasi momento per proteggere il tuo account.",
          privacyTitle: "Verifica i tuoi consensi privacy",
          privacySubTitle:
            "Puoi verificare o modificare qui i tuoi consensi e le tue sottoscrizioni.",
          cancelTitle: "Cancella il mio account",
          cancelSubTitle:
            "Cancellazione dell'account e della Fidelity card Via Milano Program.",
          telepassTitle: "Associa il tuo codice Telepass",
          telepassSubtitle:
            "Associando il tuo codice Telepass e accumuli punti ViaMilano Program",
          telepassNumber: "Numero Telepass",
          preferenceTitle: "Preferenze: più aggiungi più ottieni",
          preferenceSubtitle:
            "Desideri che SEA possa conoscerti meglio per offriti un servizio migliore e in linea con le tue esigenze?",
          fidelityCardNumber: "Numero carta:",
          showFidelityCard: "Visualizza carta fedeltà",
          getYourFidelityCard: "Ottieni la tua carta fedeltà",
          fidelityCard: "La mia carta fedeltà",
          flightsTracking: "Tracking voli",
          myPurchases: "I miei acquisti",
          myAccount: "Il mio account",
          logout: "Logout",
          flightTrackingLast6Month: "Ultimi 6 mesi",
          flightTrackingLast3Month: "Ultimi 3 mesi",
          flightTrackingLastMonth: "Ultimo mese",
          genderM: "Maschio",
          genderF: "Femmina",
          cardSubscription :"Iscriviti al programma che ti permette di raccogliere punti ad ogni acquisto ottenendo premi e vantaggi.",
          notFound: "Non associato",
          subscribe: "Iscriviti"
        },
        parking: {
          searchParking: "Cerca parcheggi",
          today: "Oggi",
          hour: "Ora",
          time: "Orario",
          chooseTime: 'Seleziona ora',
          list: 'Elenco',
          map: 'Mappa'
        },
        trains: {
          searchTrain: "Cerca treni",
          linkTrain: "/treno"
        },
        travel_inspiration: {
          city: "Città",
          sea: "Mare",
          culture: "Cultura",
          nature: "Natura",
          departuresCheckIn2: "Arrivare in aeroporto in anticipo di ore: 2",
          findYourFlightTo: "Trova il tuo volo per "
        },
        destinations: {
          noResult: "La tua ricerca non ha prodotto risultati.",
          selectContinent: "Seleziona continente",
          selectNation: "Seleziona nazione/territorio",
          selectCity: "Seleziona città",
          flight: "Volo",
          airline: "Compagnia aerea",
          airport: "Aeroporto",
          activity: "Operatività voli",
          activityShort: "Attività",
          frequency: "Orario e frequenza",
          frequencyShort: "Frequenza"
        },
        airlines: {
          selectCode: "Seleziona codice",
          airlines: "Compagnia aerea",
          code: "Codice",
          goToWebSite: "VAI AL SITO",
          contactAirline: "Contatta la compagnia aerea",
          contact: "Contatta"
        },
        purchases: {
          date: "Data Ordine",
          details: "Dettagli",
          pnr: "Codice d'acquisto (PNR)",
          tot: "Totale",
          ticket: "VISUALIZZA TICKET",
          shopping: "Inizia a fare acquisti!",
          nopurchases: "Nessun acquisto presente"
        },
        loyalty: {
          programDescription: "Con la tua carta fedeltà Via Milano Program potrai guadagnare punti con i tuoi acquisti di prodotti o servizi presso l’aeroporto di Milano Malpensa. Nei negozi basterà mostrare il codice della carta fedeltà in fase di acquisto.",
          rules: 'Scopri il regolamento',
          knowMore: 'Scopri di più',
          showFidelityProgram: 'Programma',
          downloadCard: 'Scarica carta fedeltà',
          myFidelityProgram: 'Il mio Programma Fedeltà',
          pointsLabel: 'Hai totalizzato',
          points: 'Punti',
          pointsOverview: 'Panoramica punti',
          date: 'Data',
          detail: 'Dettaglio',
          rewardCatalog: 'Catalogo premi',
          myRewards: 'I miei premi',
          rewardHistoryCta: 'Vedi il mio storico premi',
          residue: 'Residuo',
          request: 'Richiedi',
          confirm: "Sei sicuro di voler confermare l'operazione?",
          cancel: 'Annulla',
        },
        news: {
          seeAllTheNews: "VEDI TUTTE LE NOTIZIE",
          readArticle: 'LEGGI ARTICOLO'
        },
        faqs: {
          seeAllTheFaqs: "VEDI TUTTE LE FAQS"
        },
        globalSearch: {
          search: "Cerca...",
          searchBtn: "CERCA",
          delete: "Cancella",
          noResults: "Non ci sono risultati per ",
          all: "Tutti i ",
          resultsFor: " risultati di ricerca per ",
          visitPage: "VISITA PAGINA"
        }
      },
    },
  },
});
