import { t } from "i18next";
import CustomIcon from "../CustomIcon/CustomIcon";
import { IFlightItem } from "../FlightRow/FlightRow";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { LanguageAtom } from "@/atoms";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks";

interface IDestinationFlightRowProps {
    flightItem: IFlightItem,
    actionColumn?: boolean,
    actionLabel?: string,
    actionLink?: string,
    targetBlank?: boolean
}

const getDaysFromFrequency: (frequency: string, lang?: string) => string = (frequency: string, lang = 'en') => {
    if (!frequency) { console.log('Missing frequency'); return '' };
    if (frequency && frequency.length < 7) { console.log('No sufficient digit for frequency calc'); return '' }
    let result = '';
    const cleanArray = frequency.replace(" ", "").split("");
    let occCount: number = 0;
    cleanArray.forEach((digit) => {
        const currentNum: number = Number(digit);
        if (currentNum !== 0) occCount++
    })
    cleanArray.forEach((item, index) => {
        let currentNumber: number = Number(item)
        currentNumber !== 0 ? result += `${(result) && (occCount > 0) ? ' |' : ''} ${getDayFromIndex(index, lang)}` : ''
    })
    /* const scheduleString = frequency.replace(" ", "").split("").reduce((accumulator, newElement, index) => {
        return accumulator += (getDayFromIndex(index) + " | ")
        return !!newElement ? accumulator += (getDayFromIndex(index) + " | ") : accumulator
    }) */

    return result as string;
}

const getDayFromIndex = (index: number, lang = 'en') => {
    let result;
    switch (index) {
        case 0: result = lang === 'en' ? 'MON' : 'LUN';
            break;
        case 1: result = lang === 'en' ? 'TUE' : 'MAR';
            break;
        case 2: result = lang === 'en' ? 'WED' : 'MER';
            break;
        case 3: result = lang === 'en' ? 'THU' : 'GIO';
            break;
        case 4: result = lang === 'en' ? 'FRI' : 'VEN';
            break;
        case 5: result = lang === 'en' ? 'SAT' : 'SAB';
            break;
        case 6: result = lang === 'en' ? 'SUN' : 'DOM';
            break;
    }
    return result;
}

const DestinationFlightRow: React.FC<IDestinationFlightRowProps> = ({ actionLink = '', actionColumn = false, flightItem, actionLabel = '', targetBlank = false }: IDestinationFlightRowProps) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [isMobile] = useIsMobile();
    const freqString = useMemo(() => getDaysFromFrequency(flightItem.frequency, lang), [flightItem, lang]);
    const actionBuy = !actionColumn
			? 'grid-cols-[2fr_3fr_2fr] desk:grid-cols-[1fr_2fr_2fr_2fr_2fr]'
			: 'grid-cols-[2fr_3fr_2fr] desk:grid-cols-[1fr_2fr_2fr_2fr_2fr_1fr]';

    return (
        <div className={`text-sm desk:text-base text-center grid gap-4 border-b-2 py-4 desk:py-7 border-b-lightgrey ${actionBuy}`}>
            <div className="">{flightItem.flightNumber} <div className="desk:hidden pt-1 font-semibold">{flightItem.airlineDescription}</div></div>
            <div className="hidden desk:block font-semibold">{flightItem.airlineDescription}</div>
            <div className="hidden desk:block font-semibold">{flightItem.routing[1].airportDescription}</div>
            <div className="">{t("flights.from")} {new Date(flightItem.dateFrom).toLocaleDateString().replaceAll("/", ".")} {t("flights.to")} {new Date(flightItem.dateTo).toLocaleDateString().replaceAll("/", ".")} <div className="desk:hidden pt-1 font-semibold">{flightItem.routing[1].airportDescription}</div></div>
            <div className="">{`${flightItem.stx}${isMobile ? "" : " | "}${freqString}`}</div>
            {actionColumn ?

                <div className="mx-auto dek:mx-0 text-xs desk:text-base cursor-pointer font-bold uppercase flex col-span-3 desk:col-span-1 al">
                    <a target={targetBlank ? '_blank' : '_self'} className="w-full flex justify-end" href={actionLink}>
                        <div className="flex items-center gap-2">
                            <div>{actionLabel}</div>
                            <div>
                                <CustomIcon iconName="chevron-r" />
                            </div>
                        </div>
                    </a>
                </div>
                : null
            }
        </div>
    )
}

export default DestinationFlightRow;